<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../assets/img/applyfor/banner2.jpg" alt="" />
    </div>
    <div class="container">
      <div class="box">
        <div class="step_box">
          <el-steps :active="3" align-center>
            <el-step title="申请并注册"></el-step>
            <el-step title="上传资料"></el-step>
            <el-step title="提交成功"></el-step>
          </el-steps>
        </div>
        <div class="success_box">
          <div class="success_img">
            <img src="../../assets/img/applyfor/success.png" alt="" />
          </div>
          <div class="success_msg_box">
            <div class="success_title">恭喜，您已成功提交免费代理记账申请!</div>
            <div class="success_msg">
              请耐心等待审核，您可以<span @click="login">登录</span>
              数电通中小微企业服务生态平台查看申请进度，初始密码为手机号后6位。也可以
              <span @click="toYun">授权云凭证夹</span>
              进行后续凭证归集及票据接收
            </div>
          </div>
        </div>
        <div class="img_box">
          <div class="code_img_box">
            <div class="code_img">
              <img src="../../assets/img/applyfor/qrcode.png" alt="" />
            </div>
            <div class="code_title">金卡易联云会计服务平台公众号</div>
          </div>
          <div class="code_img_box">
            <div class="code_img">
              <img src="../../assets/img/applyfor/minicode.png" alt="" />
            </div>
            <div class="code_title">一号密巢云凭证夹小程序</div>
          </div>
        </div>
        <div class="btn" @click="toYun">授权云凭证夹</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    login() {
      window.location.href = "http://mapi.cfcasmes.com/";
    },
    toYun() {
      window.location.href = "https://drive.cfcasmes.com/#/login";
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.banner {
  width: 100%;
  height: 400px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.container {
  padding: 60px;
}

.container_bg {
  background: #f5f5f5;
}

.box {
  width: 1200px;
  margin: 0 auto;
}

.success_box {
  width: 1000px;
  display: flex;
  margin: 50px auto 20px;
}

.success_msg_box {
  flex: 1;
  padding-left: 40px;
}

.success_img {
  width: 278px;
  height: 218px;
}

.success_img img {
  display: block;
  width: 100%;
}

.success_title {
  font-size: 36px;
  font-weight: bold;
  color: #000000;
}

.success_msg {
  font-size: 22px;
  font-weight: 500;
  color: #000000;
  margin-top: 30px;
}

.success_msg span {
  color: #045afd;
  cursor: pointer;
}

.img_box {
  display: flex;
  justify-content: center;
}

.code_img_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.code_img {
  width: 224px;
  height: 224px;
  margin: 60px 160px;
}

.code_img img {
  display: block;
  width: 100%;
}

.code_title {
  font-size: 24px;
  font-weight: 500;
  color: #000000;
}

.btn {
  width: 200px;
  height: 57px;
  background: #045afd;
  border-radius: 29px;
  text-align: center;
  color: #fff;
  line-height: 57px;
  font-size: 18px;
  margin: 60px auto;
  cursor: pointer;
}
</style>
